<script>
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  MailIcon,
} from "vue-feather-icons";
export default {
  data() {
    return {};
  },
  components: {
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    MailIcon,
  },
};
</script>

<template>
  <div>
    <!-- Footer Start -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
            <a href="#" class="logo-footer">
              <img src="images/onepage/esso_logo.png" height="96" alt="" />
            </a>
            <p class="mt-4">
              Esso Otomasyon Sistemleri olarak en gelişmiş teknolojileri
              kullanarak, verimliliği en iyi hale getiriyor ve sürekli olarak
              geliştiriyoruz.
            </p>
            <ul class="list-unstyled social-icon social mb-0 mt-4">
              <li class="list-inline-item ml-1">
                <a
                  href="https://www.instagram.com/ercsistemelektrik/"
                  class="rounded"
                >
                  <instagram-icon
                    class="fea icon-sm fea-social"
                  ></instagram-icon>
                </a>
              </li>

              <li class="list-inline-item ml-1">
                <a
                  href="https://www.linkedin.com/company/esso-otomasyon-a-s/"
                  class="rounded"
                >
                  <linkedin-icon class="fea icon-sm fea-social"></linkedin-icon>
                </a>
              </li>
            </ul>
            <!--end icon-->
          </div>
          <!--end col-->
          <div class="col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"></div>

          <div class="col-md-2 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0 ">
            <h5 class="text-light footer-head">Bağlantılar</h5>
            <ul class="list-unstyled footer-list mt-4">
              <li>
                <router-link to="/" class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Ana
                  Sayfa</router-link
                >
              </li>
              <li>
                <a
                  v-scroll-to="'#features'"
                  class="text-foot"
                  style="cursor: pointer"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Özelliklerimiz</a
                >
              </li>
              <li>
                <a
                  v-scroll-to="'#services'"
                  style="cursor: pointer"
                  class="text-foot"
                  ><i class="mdi mdi-chevron-right mr-1"></i> Hizmetlerimiz</a
                >
              </li>
              <li>
                <a
                  v-scroll-to="'#faq'"
                  class="text-foot"
                  style="cursor: pointer"
                  ><i class="mdi mdi-chevron-right mr-1"></i> SSS</a
                >
              </li>
              <li>
                <a
                  v-scroll-to="'#contact'"
                  class="text-foot"
                  style="cursor: pointer"
                  ><i class="mdi mdi-chevron-right mr-1"></i> İletişim</a
                >
              </li>
            </ul>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </footer>
    <!--end footer-->
    <footer class="footer footer-bar">
      <div class="container text-center">
        <div class="row align-items-center justify-content-center">
          <div class="col-sm-12 ">
            <div class="text-sm-left">
              <p class="mb-0">
                © 2020 - {{ new Date().getFullYear() }} Esso Otomasyon. Design
                with <i class="mdi mdi-heart text-danger"></i> by
                <a
                  href="https://www.raquun.com/?utm_source=esso.com.tr&utm_medium=copyright"
                  target="_blank"
                  class="text-danger"
                  >Raquun IoT & Yazılım A.Ş.</a
                >
              </p>
            </div>
          </div>

          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </footer>
    <!--end footer-->
  </div>
</template>
