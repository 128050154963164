<script>
import {
  ArrowUpIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  PhoneIcon,
  MapPinIcon,
  MailIcon,
  MessageCircleIcon,
  UserIcon,
  KeyIcon,
} from "vue-feather-icons";
import countTo from "vue-count-to";
import Footer from "@/components/footer";

/**
 * Index-onepage component
 */
export default {
  data() {
    return {
      isCondensed: false,
    };
  },
  components: {
    Footer,
    ArrowUpIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    PhoneIcon,
    MapPinIcon,
    MailIcon,
    MessageCircleIcon,
    UserIcon,
    KeyIcon,
    countTo,
  },
  mounted: () => {
    window.onscroll = function() {
      onwindowScroll();
    };

    function onwindowScroll() {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        document.getElementById("topnav").classList.add("nav-sticky");
      } else {
        document.getElementById("topnav").classList.remove("nav-sticky");
      }

      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        document.getElementById("back-to-top").style.display = "inline";
      } else {
        document.getElementById("back-to-top").style.display = "none";
      }
    }
  },
  methods: {
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.isCondensed = !this.isCondensed;
      if (this.isCondensed) {
        document.getElementById("navigation").style.display = "block";
      } else document.getElementById("navigation").style.display = "none";
    },
  },
};
</script>

<template>
  <div>
    <!-- Navbar STart -->
    <header id="topnav" class="defaultscroll sticky">
      <div class="container">
        <!-- Logo container-->
        <div>
          <a class="logo" href="#">
            <img src="images/onepage/esso_logo.png" height="42" alt="" />
          </a>
        </div>
        <div class="buy-button">
          <ul class="list-unstyled social-icon social mb-0 mt-3">
            <li class="list-inline-item ml-1">
              <a
                href="https://www.instagram.com/ercsistemelektrik/"
                class="rounded"
              >
                <instagram-icon class="fea icon-sm fea-social"></instagram-icon>
              </a>
            </li>

            <li class="list-inline-item ml-1">
              <a
                href="https://www.linkedin.com/company/esso-otomasyon-a-s/"
                class="rounded"
              >
                <linkedin-icon class="fea icon-sm fea-social"></linkedin-icon>
              </a>
            </li>
          </ul>
        </div>
        <!--end login button-->
        <!-- End Logo container-->

        <div id="navigation">
          <!-- Navigation Menu-->
          <ul
            class="navigation-menu"
            v-scroll-spy-active="{ selector: 'li.has-submenu' }"
          >
            <li class="has-submenu">
              <a href="javascript: void(0);" v-scroll-to="'#home'">ANA SAYFA</a>
            </li>
            <li class="has-submenu">
              <a href="javascript: void(0);" v-scroll-to="'#features'"
                >ÖZELLİKLERİMİZ</a
              >
            </li>
            <li class="has-submenu">
              <a href="javascript: void(0);" v-scroll-to="'#services'"
                >HİZMETLERİMİZ</a
              >
            </li>
            <li class="has-submenu">
              <a href="javascript: void(0);" v-scroll-to="'#faq'">SSS</a>
            </li>
            <li class="has-submenu">
              <a href="javascript: void(0);" v-scroll-to="'#contact'"
                >İLETİŞİM</a
              >
            </li>
          </ul>
          <!--end navigation menu-->
        </div>
        <!--end navigation-->
      </div>
      <!--end container-->
    </header>
    <!--end header-->
    <!-- Navbar End -->
    <div v-scroll-spy>
      <!-- Hero Start -->
      <section
        class="bg-home bg-light d-flex align-items-center"
        style="
          background: url('images/onepage/bg.png') center center;
          height: auto;
        "
        id="home"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-9 text-center mt-0 mt-md-5 pt-0 pt-md-5">
              <div class="title-heading margin-top-100">
                <small class="text-muted"><b>ARADIĞINIZ ÇÖZÜM</b></small>
                <h2>Sizin için en güvenilir</h2>
                <h1
                  class="heading mb-3"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <span class="text-primary" style="font-size: 6rem;"
                    >Otomasyon</span
                  >
                </h1>
                <p
                  class="para-desc mx-auto text-muted"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                >
                  Esso Otomasyon Sistemleri olarak en gelişmiş teknolojileri
                  kullanarak, verimliliği en iyi hale getiriyor ve sürekli
                  olarak geliştiriyoruz.
                </p>
                <div
                  class="text-center  mt-4 pt-2"
                  data-aos="fade-up"
                  data-aos-duration="1800"
                >
                  <div class="form-group mb-0">
                    <button
                      type="submit"
                      v-scroll-to="'#contact'"
                      class="btn btn-pills btn-primary"
                    >
                      İletişime Geç
                    </button>
                  </div>
                </div>
              </div>

              <div
                class="home-dashboard onepage-hero"
                data-aos="fade-up"
                data-aos-duration="2200"
              >
                <img
                  src="images/onepage/1.jpg"
                  alt=""
                  class="img-fluid rounded-md shadow-md"
                />
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </section>
      <!--end section-->
      <!-- Hero End -->

      <!-- Features Start -->
      <section class="section pb-0" id="features">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <div class="section-title mb-4 pb-2">
                <h4
                  class="title mb-4"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Özelliklerimiz
                </h4>
                <p
                  class="text-muted para-desc mx-auto mb-0"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                >
                  <span class="text-primary font-weight-bold"
                    >Esso Otomasyon</span
                  >
                  olarak, endüstriyel otomasyon çözümlerinde en son
                  teknolojileri ve yüksek verimliliği bir araya getiriyoruz.
                  Yenilikçi yaklaşımımız ve müşteri odaklı hizmet anlayışımız
                  ile işletmenizin verimliliğini artırmak için buradayız.
                </p>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->

          <div class="row">
            <div class="col-md-4 col-12">
              <div class="features mt-5">
                <div class="image position-relative d-inline-block">
                  <i class="uil uil-rocket h1 text-primary"></i>
                </div>

                <div class="content mt-4">
                  <h5>Yüksek Performanslı Kontrol Sistemleri</h5>
                  <p class="text-muted mb-0">
                    Gelişmiş kontrol sistemlerimiz, üretim süreçlerinizin
                    sorunsuz ve etkin bir şekilde yönetilmesini sağlar. Bu
                    sistemler, gerçek zamanlı veri analizi ve hızlı tepki
                    süreleri ile donatılmıştır.
                  </p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-4 col-12 mt-5">
              <div class="features">
                <div class="image position-relative d-inline-block">
                  <i class="uil uil-brain h1 text-primary"></i>
                </div>

                <div class="content mt-4">
                  <h5>Akıllı Sensör Teknolojisi</h5>
                  <p class="text-muted mb-0">
                    Akıllı sensörlerimiz, üretim hattınızın her aşamasında
                    hassas ve doğru ölçümler yaparak, üretim kalitesini ve
                    verimliliğini artırır. Bu sensörler, otomasyon sisteminizle
                    kusursuz bir şekilde entegre olur.
                  </p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-4 col-12 mt-5">
              <div class="features">
                <div class="image position-relative d-inline-block">
                  <i class="uil uil-apps h1 text-primary"></i>
                </div>

                <div class="content mt-4">
                  <h5>Entegre Yazılım Çözümleri</h5>
                  <p class="text-muted mb-0">
                    Esso Otomasyon’un entegre yazılım çözümleri, üretim
                    süreçlerinizi optimize etmek ve işletme maliyetlerinizi
                    düşürmek için tasarlanmıştır. Kullanıcı dostu arayüzler ve
                    güçlü veri işleme kapasitesi sunar.
                  </p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-4 col-12 mt-5">
              <div class="features">
                <div class="image position-relative d-inline-block">
                  <i class="uil uil-lightbulb-alt h1 text-primary"></i>
                </div>

                <div class="content mt-4">
                  <h5>Enerji Yönetim Sistemleri</h5>
                  <p class="text-muted mb-0">
                    Enerji yönetim sistemlerimiz, enerji tüketiminizi izleyerek
                    ve analiz ederek, tasarruf yapmanızı ve çevresel ayak
                    izinizi azaltmanızı sağlar. Bu sistemler, sürdürülebilir ve
                    verimli bir enerji kullanımı sunar.
                  </p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-4 col-12 mt-5">
              <div class="features">
                <div class="image position-relative d-inline-block">
                  <i class="uil uil-monitor h1 text-primary"></i>
                </div>

                <div class="content mt-4">
                  <h5>Uzaktan İzleme ve Kontrol</h5>
                  <p class="text-muted mb-0">
                    Uzaktan izleme ve kontrol sistemlerimiz, tesislerinizi
                    dünyanın her yerinden yönetebilmenizi sağlar. Bu özellik,
                    operasyonel verimliliği artırır ve arıza durumlarında hızlı
                    müdahale imkanı sunar.
                  </p>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-md-4 col-12 mt-5">
              <div class="features">
                <div class="image position-relative d-inline-block">
                  <i class="uil uil-comment-alt-question h1 text-primary"></i>
                </div>

                <div class="content mt-4">
                  <h5>Teknik Destek ve Eğitim Hizmetleri</h5>
                  <p class="text-muted mb-0">
                    Esso Otomasyon, teknik destek ve eğitim hizmetleri ile
                    müşterilerinin her zaman yanında. Uzman ekibimiz,
                    sistemlerinizin sorunsuz çalışması ve personelinizin tam
                    donanımlı olması için gerekli tüm desteği sağlar.
                  </p>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->

        <div class="container mt-100 mt-60">
          <div
            class="p-4 rounded bg-light shadow"
            data-aos="fade-up"
            data-aos-duration="1400"
          >
            <div class="row align-items-center">
              <div class="col-sm-7">
                <div class="text-sm-left">
                  <h5 class="mb-0">
                    Esso Otomasyon ile Verimlilikte Yeni Bir Dönem Başlatın.
                  </h5>
                </div>
              </div>
              <!--end col-->

              <div class="col-sm-5 mt-4 mt-sm-0">
                <div class="text-sm-right">
                  <a
                    href="javascript:void(0)"
                    v-scroll-to="'#contact'"
                    class="btn btn-outline-primary"
                    >İletişime Geç
                  </a>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row -->
          </div>
        </div>
        <!--end container-->

        <div class="container mt-100 mt-60">
          <div class="row">
            <div class="col-12">
              <div
                class="position-relative"
                style="z-index: 1"
                data-aos="zoom-in"
                data-aos-duration="1600"
              >
                <img
                  src="images/onepage/laptop.png"
                  class="rounded img-fluid mx-auto d-block"
                  alt=""
                />
              </div>
            </div>
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </section>
      <!--end section-->
      <div class="position-relative">
        <div class="shape overflow-hidden text-light">
          <svg
            viewBox="0 0 2880 250"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M720 125L2160 0H2880V250H0V125H720Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <!-- Features End -->

      <!-- Review Start -->
      <section class="section bg-light" id="services">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <div class="section-title mb-4 pb-2">
                <h4
                  class="title mb-4"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Hizmetlerimiz
                </h4>
                <p
                  class="text-muted para-desc mb-0 mx-auto"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                >
                  <span class="text-primary font-weight-bold"
                    >Esso Otomasyon</span
                  >
                  olarak, enerji alanındaki otomasyon ihtiyaçlarınızı karşılamak
                  için geniş bir hizmet yelpazesi sunuyoruz. Her projede, kalite
                  ve müşteri memnuniyeti odaklı çalışarak, işletmenizin
                  verimliliğini ve rekabet gücünü artırmayı hedefliyoruz.
                </p>
              </div>
            </div>
          </div>
          <!--end row-->

          <div class="row">
            <div class="col-lg-4 col-md-6 mt-4 pt-2">
              <div
                class="media key-feature align-items-center p-3 rounded shadow"
              >
                <div class="icon text-center rounded-circle mr-3">
                  <monitor-icon
                    class="fea icon-ex-md text-primary"
                  ></monitor-icon>
                </div>
                <div class="media-body">
                  <h4 class="title mb-0">
                    Dizel Yakıtlı Jeneratör Senkronizasyonu
                  </h4>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
              <div
                class="media key-feature align-items-center p-3 rounded shadow"
              >
                <div class="icon text-center rounded-circle mr-3">
                  <heart-icon class="fea icon-ex-md text-primary"></heart-icon>
                </div>
                <div class="media-body">
                  <h4 class="title mb-0">
                    Gaz Yakıtlı Jeneratör Senkronizasyonu
                  </h4>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
              <div
                class="media key-feature align-items-center p-3 rounded shadow"
              >
                <div class="icon text-center rounded-circle mr-3">
                  <eye-icon class="fea icon-ex-md text-primary"></eye-icon>
                </div>
                <div class="media-body">
                  <h4 class="title mb-0">
                    Çoklu Jeneratör Çoklu Şebeke Senkronizasyonu
                  </h4>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
              <div
                class="media key-feature align-items-center p-3 rounded shadow"
              >
                <div class="icon text-center rounded-circle mr-3">
                  <bold-icon class="fea icon-ex-md text-primary"></bold-icon>
                </div>
                <div class="media-body">
                  <h4 class="title mb-0">Kontrol Panoları</h4>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
              <div
                class="media key-feature align-items-center p-3 rounded shadow"
              >
                <div class="icon text-center rounded-circle mr-3">
                  <feather-icon
                    class="fea icon-ex-md text-primary"
                  ></feather-icon>
                </div>
                <div class="media-body">
                  <h4 class="title mb-0">Enerji Otomasyonu</h4>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
              <div
                class="media key-feature align-items-center p-3 rounded shadow"
              >
                <div class="icon text-center rounded-circle mr-3">
                  <code-icon class="fea icon-ex-md text-primary"></code-icon>
                </div>
                <div class="media-body">
                  <h4 class="title mb-0">Aydınlatma Otomasyonu</h4>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
              <div
                class="media key-feature align-items-center p-3 rounded shadow"
              >
                <div class="icon text-center rounded-circle mr-3">
                  <user-check-icon
                    class="fea icon-ex-md text-primary"
                  ></user-check-icon>
                </div>
                <div class="media-body">
                  <h4 class="title mb-0">SCADA</h4>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
              <div
                class="media key-feature align-items-center p-3 rounded shadow"
              >
                <div class="icon text-center rounded-circle mr-3">
                  <git-merge-icon
                    class="fea icon-ex-md text-primary"
                  ></git-merge-icon>
                </div>
                <div class="media-body">
                  <h4 class="title mb-0">Kojenerasyon Otomasyonu</h4>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-4 col-md-6 mt-4 pt-2">
              <div
                class="media key-feature align-items-center p-3 rounded shadow"
              >
                <div class="icon text-center rounded-circle mr-3">
                  <settings-icon
                    class="fea icon-ex-md text-primary"
                  ></settings-icon>
                </div>
                <div class="media-body">
                  <h4 class="title mb-0">Ve Diğerleri...</h4>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-12 mt-4 pt-2 text-center">
              <a
                href="javascript:void(0)"
                v-scroll-to="'#contact'"
                class="btn btn-primary"
                >Daha fazlası için iletişime geç
                <i class="mdi mdi-arrow-right"></i
              ></a>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </section>
      <!--end section-->
      <!-- Review End -->
      <!-- Faq Start -->
      <section class="section" id="faq">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <div class="section-title mb-4 pb-2">
                <h4 class="title mb-4">
                  <span class="text-primary">Esso Otomasyon</span> ile tüm
                  ihtiyaçlarınızı karşılayın
                </h4>
                <p class="text-muted para-desc mx-auto mb-0">
                  <span class="text-primary font-weight-bold"
                    >Esso Otomasyon</span
                  >
                  ile çalışmaya başlayarak üretim süreçlerinizi optimize etme,
                  verimliliği artırma ve endüstriyel operasyonlarınızı modernize
                  etme konusundaki tüm ihtiyaçlarınızı karşılayacak çözümleri
                  elde edebilirsiniz.
                </p>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->

          <div class="row" id="counter">
            <div class="col-md-3 col-6 mt-4 pt-2">
              <div class="counter-box text-center">
                <img
                  src="images/illustrator/Asset190.svg"
                  class="avatar avatar-small"
                  alt=""
                />
                <h2 class="mb-0 mt-4">
                  <countTo :startVal="3" :endVal="97" :duration="4000"></countTo
                  >%
                </h2>
                <h6 class="counter-head text-muted">Mutlu Müşteri</h6>
              </div>
              <!--end counter box-->
            </div>

            <div class="col-md-3 col-6 mt-4 pt-2">
              <div class="counter-box text-center">
                <img
                  src="images/illustrator/Asset189.svg"
                  class="avatar avatar-small"
                  alt=""
                />
                <h2 class="mb-0 mt-4">
                  <countTo :startVal="1" :endVal="80" :duration="4000"></countTo
                  >+
                </h2>
                <h6 class="counter-head text-muted">Yetenekli Uzman</h6>
              </div>
              <!--end counter box-->
            </div>

            <div class="col-md-3 col-6 mt-4 pt-2">
              <div class="counter-box text-center">
                <img
                  src="images/illustrator/Asset192.svg"
                  class="avatar avatar-small"
                  alt=""
                />
                <h2 class="mb-0 mt-4">
                  <countTo
                    :startVal="0"
                    :endVal="491"
                    :duration="4000"
                  ></countTo
                  >+
                </h2>
                <h6 class="counter-head text-muted">Tamamlanmış Proje</h6>
              </div>
              <!--end counter box-->
            </div>

            <div class="col-md-3 col-6 mt-4 pt-2">
              <div class="counter-box text-center">
                <img
                  src="images/illustrator/Asset187.svg"
                  class="avatar avatar-small"
                  alt=""
                />
                <h2 class="mb-0 mt-4">
                  <countTo
                    :startVal="1"
                    :endVal="120"
                    :duration="4000"
                  ></countTo
                  >+
                </h2>
                <h6 class="counter-head text-muted">Aylık Bakım İşlemi</h6>
              </div>
              <!--end counter box-->
            </div>
          </div>
          <!--end row-->
        </div>
        <!--end container-->

        <div class="container mt-100 mt-60" id="faq">
          <div class="row align-items-center">
            <div class="col-lg-7 col-md-6">
              <div class="faq-content mr-lg-5">
                <div class="accordion" id="accordionExampleone">
                  <b-card no-body class="mb-2 border-0 rounded">
                    <b-card-header class="border-0 bg-light p-3 pr-5">
                      <a
                        class="faq position-relative text-dark"
                        v-b-toggle.accordion-1
                        href="javascript: void(0);"
                      >
                        <h6 class="title mb-0">
                          <span class="text-primary">Esso Otomasyon</span> hangi
                          endüstrilere hizmet vermektedir?
                        </h6>
                      </a>
                    </b-card-header>
                    <b-collapse
                      id="accordion-1"
                      visible
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body class="px-2 py-4">
                        <p class="text-muted mb-0 faq-ans">
                          Esso Otomasyon, enerji başta olmak üzere birçok
                          endüstriye özel otomasyon çözümleri sunmaktadır.
                        </p>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                  <b-card no-body class="mb-2 border-0 rounded">
                    <b-card-header class="border-0 bg-light p-3 pr-5">
                      <a
                        class="faq position-relative text-dark"
                        v-b-toggle.accordion-2
                        href="javascript: void(0);"
                      >
                        <h6 class="title mb-0">
                          Esso Otomasyon’un sunduğu çözümler özelleştirilebilir
                          mi?
                        </h6>
                      </a>
                    </b-card-header>
                    <b-collapse
                      id="accordion-2"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body class="px-2 py-4">
                        <p class="text-muted mb-0 faq-ans">
                          Evet, Esso Otomasyon’un çözümleri, her müşterinin
                          spesifik ihtiyaçlarına göre özelleştirilebilir ve
                          tasarlanabilir.
                        </p>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                  <b-card no-body class="mb-2 border-0 rounded">
                    <b-card-header class="border-0 bg-light p-3 pr-5">
                      <a
                        class="faq position-relative text-dark"
                        v-b-toggle.accordion-3
                        href="javascript: void(0);"
                      >
                        <h6 class="title mb-0">
                          Projelerinizde hangi teknolojileri kullanıyorsunuz?
                        </h6>
                      </a>
                    </b-card-header>
                    <b-collapse
                      id="accordion-3"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body class="px-2 py-4">
                        <p class="text-muted mb-0 faq-ans">
                          Projelerimizde en son teknolojileri ve endüstri
                          standartlarını kullanıyoruz, örneğin akıllı sensörler,
                          IoT entegrasyonları, yapay zeka ve makine öğrenimi
                          tabanlı sistemler.
                        </p>
                      </b-card-body>
                    </b-collapse>
                  </b-card>

                  <b-card no-body class="mb-2 border-0 rounded">
                    <b-card-header class="border-0 bg-light p-3 pr-5">
                      <a
                        class="faq position-relative text-dark"
                        v-b-toggle.accordion-4
                        href="javascript: void(0);"
                      >
                        <h6 class="title mb-0">
                          Uzaktan izleme ve kontrol imkanı sunuyor musunuz?
                        </h6>
                      </a>
                    </b-card-header>
                    <b-collapse
                      id="accordion-4"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body class="px-2 py-4">
                        <p class="text-muted mb-0 faq-ans">
                          Evet, uzaktan izleme ve kontrol sistemlerimiz
                          sayesinde tesislerinizi dünyanın her yerinden
                          yönetebilir ve kontrol edebilirsiniz.
                        </p>
                      </b-card-body>
                    </b-collapse>
                  </b-card>

                  <b-card no-body class="mb-2 border-0 rounded">
                    <b-card-header class="border-0 bg-light p-3 pr-5">
                      <a
                        class="faq position-relative text-dark"
                        v-b-toggle.accordion-5
                        href="javascript: void(0);"
                      >
                        <h6 class="title mb-0">
                          Teknik destek ve bakım hizmetleriniz neleri kapsar
                        </h6>
                      </a>
                    </b-card-header>
                    <b-collapse
                      id="accordion-5"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body class="px-2 py-4">
                        <p class="text-muted mb-0 faq-ans">
                          Teknik destek ve bakım hizmetlerimiz, sistem kurulumu,
                          arıza giderme, periyodik bakım, yazılım güncellemeleri
                          ve personel eğitimi gibi kapsamlı hizmetleri içerir.
                        </p>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <img src="images/illustrator/faq.svg" alt="" />
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </section>
      <!--end section-->
      <!-- Faq End -->

      <!-- Contact Start -->
      <section class="section pb-0 bg-light" id="contact">
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              <div class="section-title mb-4 pb-2">
                <h4
                  class="title mb-4"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Bize Ulaşın !
                </h4>
                <p
                  class="text-muted para-desc mx-auto mb-0"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                >
                  <span class="text-primary font-weight-bold"
                    >Esso Otomasyon</span
                  >
                  olarak, size en iyi hizmeti sunmak için buradayız.
                  Sorularınızı yanıtlamak, projelerinizde size rehberlik etmek
                  ve işinizi daha verimli hale getirmek için bizimle iletişime
                  geçin. Ekibimiz, ihtiyaçlarınıza özel çözümler sunmak için her
                  zaman hazır.
                </p>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->

          <div class="row align-items-center">
            <div class="col-lg-5 col-md-6 mt-4 pt-2 order-2 order-md-1">
              <div
                class="card rounded shadow border-0"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div class="card-body">
                  <div class="custom-form bg-white">
                    <div id="message"></div>

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label
                            >Adınız <span class="text-danger">*</span></label
                          >
                          <div class="position-relative">
                            <user-icon class="fea icon-sm icons"></user-icon>
                            <input
                              name="name"
                              id="name"
                              type="text"
                              class="form-control pl-5"
                              placeholder="Adınız :"
                            />
                          </div>
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label
                            >Email Adresiniz
                            <span class="text-danger">*</span></label
                          >
                          <div class="position-relative">
                            <mail-icon class="fea icon-sm icons"></mail-icon>
                            <input
                              name="email"
                              id="email2"
                              type="email"
                              class="form-control pl-5"
                              placeholder="Email adresi :"
                            />
                          </div>
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Mesaj</label>
                          <div class="position-relative">
                            <message-circle-icon
                              class="fea icon-sm icons"
                            ></message-circle-icon>
                            <textarea
                              name="comments"
                              id="comments"
                              rows="4"
                              class="form-control pl-5"
                              placeholder="Mesajınız :"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--end row-->
                    <div class="row">
                      <div class="col-sm-12 text-center">
                        <a class="btn btn-primary btn-block">
                          Mesaj Gönder
                        </a>
                        <div id="simple-msg"></div>
                      </div>
                      <!--end col-->
                    </div>
                    <!--end row-->
                    <!--end form-->
                  </div>
                  <!--end custom-form-->
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-7 col-md-6 mt-4 pt-2 order-1 order-md-2">
              <div class="title-heading ml-lg-4">
                <h4 class="mb-4" data-aos="fade-up" data-aos-duration="1000">
                  İletişim Bilgileri
                </h4>
                <p
                  class="text-muted"
                  data-aos="fade-up"
                  data-aos-duration="1400"
                >
                  Bize ulaşmak için aşağıdaki iletişim bilgilerini
                  kullanabilirsiniz. Her türlü soru ve talepleriniz için
                  ekibimizle iletişime geçmekten çekinmeyin.
                </p>
                <div class="card border-0 bg-transparent">
                  <div class="card-body p-0">
                    <div
                      class="contact-detail d-flex align-items-center mt-3"
                      data-aos="fade-up"
                      data-aos-duration="1200"
                    >
                      <div class="icon">
                        <mail-icon
                          class="fea icon-m-md text-dark mr-3"
                        ></mail-icon>
                      </div>
                      <div class="content overflow-hidden d-block">
                        <h6 class="font-weight-bold mb-0">Email</h6>
                        <a href="mailto:info@esso.com.tr" class="text-primary"
                          >info@esso.com.tr</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card border-0 bg-transparent">
                  <div class="card-body p-0">
                    <div
                      class="contact-detail d-flex align-items-center mt-3"
                      data-aos="fade-up"
                      data-aos-duration="1400"
                    >
                      <div class="icon">
                        <phone-icon
                          class="fea icon-m-md text-dark mr-3"
                        ></phone-icon>
                      </div>
                      <div class="content overflow-hidden d-block">
                        <h6 class="font-weight-bold mb-0">Telefon</h6>
                        <a href="tel:+90 312 387 52 80" class="text-primary"
                          >+90 312 387 52 80</a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card border-0 bg-transparent">
                  <div class="card-body p-0">
                    <div
                      class="contact-detail d-flex align-items-center mt-3"
                      data-aos="fade-up"
                      data-aos-duration="1600"
                    >
                      <div class="icon">
                        <map-pin-icon
                          class="fea icon-m-md text-dark mr-3"
                        ></map-pin-icon>
                      </div>
                      <div class="content overflow-hidden d-block">
                        <h6 class="font-weight-bold mb-0">Lokasyon</h6>
                        <a
                          v-b-modal.modal-map
                          class="video-play-icon text-primary"
                          >Google Map ile Görüntüle</a
                        >
                      </div>
                      <b-modal
                        hide-footer
                        header-close-variant="white"
                        header-class="border-0"
                        content-class="border-0"
                        id="modal-map"
                        size="lg"
                        centered
                        body-class="p-0"
                      >
                        <iframe
                          height="500"
                          width="800"
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.7540642129043!2d32.7404532763853!3d39.969250982752406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d349976764520d%3A0x843fe18829bf072c!2sESSO%20OTOMASYON%20SAN.%20T%C4%B0C.%20A%C5%9E.!5e0!3m2!1str!2str!4v1721823565344!5m2!1str!2str"
                          style="border: 0"
                        ></iframe>
                      </b-modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end contact-->

        <div class="container-fluid mt-100 mt-60">
          <div class="row">
            <div class="col-12 p-0">
              <div class="map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.7540642129043!2d32.7404532763853!3d39.969250982752406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d349976764520d%3A0x843fe18829bf072c!2sESSO%20OTOMASYON%20SAN.%20T%C4%B0C.%20A%C5%9E.!5e0!3m2!1str!2str!4v1721823565344!5m2!1str!2str"
                  style="border: 0"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </section>
      <!--end section-->
      <!-- Contact End -->
    </div>
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>

<style>
.modal-content {
  background-color: transparent;
}
</style>
